import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { IsAuthenticatedGuard } from './guards/is-authenticated.guard';
import { CardUrlRequestGuard } from './guards/card-url-request.guard';

const routes: Routes = [
  {
    path: '',
    canActivate: [ IsAuthenticatedGuard ],
    loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule ),
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'register',
    loadChildren: () => import('./pages/register/register.module').then( m => m.RegisterPageModule)
  },
  {
    path: 'card-details',
    canActivate: [ IsAuthenticatedGuard ],
    loadChildren: () => import('./pages/card-details/card-details.module').then( m => m.CardDetailsPageModule)
  },
  {
    path: 'referral-cards',
    canActivate: [ IsAuthenticatedGuard ],
    loadChildren: () => import('./pages/referral-cards/referral-cards.module').then( m => m.ReferralCardsPageModule)
  },
  {
    path: 'daily-transactions',
    canActivate: [ IsAuthenticatedGuard ],
    loadChildren: () => import('./pages/daily-transactions/daily-transactions.module').then( m => m.DailyTransactionsPageModule)
  },
  {
    path: 'location-report',
    canActivate: [ IsAuthenticatedGuard ],
    loadChildren: () => import('./pages/location-report/location-report.module').then( m => m.LocationReportPageModule)
  },
  {
    path: 'settings',
    canActivate: [ IsAuthenticatedGuard ],
    loadChildren: () => import('./pages/settings/settings.module').then( m => m.SettingsPageModule)
  },
  {
    path: 'referreal-cards',
    canActivate: [ IsAuthenticatedGuard ],
    loadChildren: () => import('./pages/referral-cards/referral-cards.module').then( m => m.ReferralCardsPageModule)
  },
  {
    path: 'user',
    canActivate: [ IsAuthenticatedGuard ],
    loadChildren: () => import('./pages/user/user.module').then( m => m.UserPageModule)
  },
  {
    path: 'qrcode',
    canActivate: [ IsAuthenticatedGuard ],
    loadChildren: () => import('./pages/qrcode/qrcode.module').then( m => m.QrcodePageModule)
  },
  {
    path: 'about',
    canActivate: [ IsAuthenticatedGuard ],
    loadChildren: () => import('./pages/about/about.module').then( m => m.AboutPageModule)
  },
  {
    path: 'transaction-report',
    canActivate: [ IsAuthenticatedGuard ],
    loadChildren: () => import('./pages/transaction-report/transaction-report.module').then( m => m.TransactionReportPageModule)
  },
  {
    path: 'transaction-details',
    canActivate: [ IsAuthenticatedGuard ],
    loadChildren: () => import('./pages/transaction-details/transaction-details.module').then( m => m.TransactionDetailsPageModule)
  },
  {
    path: 'account',
    canActivate: [ IsAuthenticatedGuard ],
    loadChildren: () => import('./pages/account/account.module').then( m => m.AccountPageModule)
  },
  {
    path: 'account-edit',
    canActivate: [ IsAuthenticatedGuard ],
    loadChildren: () => import('./pages/account-edit/account-edit.module').then( m => m.AccountEditPageModule)
  },
  {
    path: 'contracts',
    canActivate: [ IsAuthenticatedGuard ],
    loadChildren: () => import('./pages/contracts/contracts.module').then( m => m.ContractsPageModule)
  },
  {
    path: 'reset-password',
    loadChildren: () => import('./pages/reset-password/reset-password.module').then( m => m.ResetPasswordPageModule)
  },
  {
    path: 'cards',
    canActivate: [ IsAuthenticatedGuard ],
    loadChildren: () => import('./pages/cards/cards.module').then( m => m.CardsPageModule)
  },
  {
    path: 'card-coupons',
    canActivate: [ IsAuthenticatedGuard ],
    loadChildren: () => import('./pages/card-coupons/coupons.module').then( m => m.CouponsPageModule)
  },
  {
    path: 'coupon-details',
    canActivate: [ IsAuthenticatedGuard ],
    loadChildren: () => import('./pages/coupon-details/coupon-details.module').then( m => m.CouponDetailsPageModule)
  },
  {
    path: 'transactions',
    canActivate: [ IsAuthenticatedGuard ],
    loadChildren: () => import('./pages/transactions/transactions.module').then( m => m.TransactionsPageModule)
  },
  {
    path: 'reset-link',
    redirectTo: 'reset-password'
    //loadChildren: () => import('./pages/reset-password/reset-password.module').then( m => m.ResetPasswordPageModule)
  },
  {
    path: 'create-card',
    canActivate: [ IsAuthenticatedGuard ],
    loadChildren: () => import('./pages/create-card/create-card.module').then( m => m.CreateCardPageModule)
  },
  {
    path: 'edit-card',
    canActivate: [ IsAuthenticatedGuard ],
    loadChildren: () => import('./pages/edit-card/edit-card.module').then( m => m.EditCardPageModule)
  },
  {
    path: 'customers',
    canActivate: [ IsAuthenticatedGuard ],
    loadChildren: () => import('./pages/customers/customers.module').then( m => m.CustomersPageModule)
  },
  {
    path: 'customer-details',
    canActivate: [ IsAuthenticatedGuard ],
    loadChildren: () => import('./pages/customer-details/customer-details.module').then( m => m.CustomerDetailsPageModule)
  },
  {
    path: 'customer-edit',
    canActivate: [ IsAuthenticatedGuard ],
    loadChildren: () => import('./pages/customer-edit/customer-edit.module').then( m => m.CustomerEditPageModule)
  },
  {
    path: 'campaigns',
    canActivate: [ IsAuthenticatedGuard ],
    loadChildren: () => import('./pages/campaigns/campaigns.module').then( m => m.CampaignsPageModule)
  },
  {
    path: 'create-campaign',
    canActivate: [ IsAuthenticatedGuard ],
    loadChildren: () => import('./pages/create-campaign/create-campaign.module').then( m => m.CreateCampaignPageModule)
  },
  {
    path: 'coupons',
    canActivate: [ IsAuthenticatedGuard ],
    loadChildren: () => import('./pages/coupons/coupons.module').then( m => m.CouponsPageModule)
  },
  {
    path: 'create-coupon',
    canActivate: [ IsAuthenticatedGuard ],
    loadChildren: () => import('./pages/create-coupon/create-coupon.module').then( m => m.CreateCouponPageModule)
  },
  {
    path: 'create-transaction',
    canActivate: [ IsAuthenticatedGuard ],
    loadChildren: () => import('./pages/create-transaction/create-transaction.module').then( m => m.CreateTransactionPageModule)
  },
  {
    path: 'tennants',
    canActivate: [ IsAuthenticatedGuard ],
    loadChildren: () => import('./pages/tennants/tennants.module').then( m => m.TennantsPageModule)
  },
  {
    path: 'create-tennant',
    canActivate: [ IsAuthenticatedGuard ],
    loadChildren: () => import('./pages/create-tennant/create-tennant.module').then( m => m.CreateTennantPageModule)
  },
  {
    path: 'tennant-details',
    canActivate: [ IsAuthenticatedGuard ],
    loadChildren: () => import('./pages/tennant-details/tennant-details.module').then( m => m.TennantDetailsPageModule)
  },
  {
    path: 'merchants',
    canActivate: [ IsAuthenticatedGuard ],
    loadChildren: () => import('./pages/merchants/merchants.module').then( m => m.MerchantsPageModule)
  },
  {
    path: 'merchant-details',
    canActivate: [ IsAuthenticatedGuard ],
    loadChildren: () => import('./pages/merchant-details/merchant-details.module').then( m => m.MerchantDetailsPageModule)
  },
  {
    path: 'create-merchant',
    canActivate: [ IsAuthenticatedGuard ],
    loadChildren: () => import('./pages/create-merchant/create-merchant.module').then( m => m.CreateMerchantPageModule)
  },
  {
    path: 'bonus',
    canActivate: [ IsAuthenticatedGuard ],
    loadChildren: () => import('./pages/bonus/bonus.module').then( m => m.BonusPageModule)
  },
  {
    path: 'create-bonus',
    canActivate: [ IsAuthenticatedGuard ],
    loadChildren: () => import('./pages/create-bonus/create-bonus.module').then( m => m.CreateBonusPageModule)
  },
  {
    path: 'contract-details',
    canActivate: [ IsAuthenticatedGuard ],
    loadChildren: () => import('./pages/contract-details/contract-details.module').then( m => m.ContractDetailsPageModule)
  },
  {
    path: 'create-contract',
    canActivate: [ IsAuthenticatedGuard ],
    loadChildren: () => import('./pages/create-contract/create-contract.module').then( m => m.CreateContractPageModule)
  },
  {
    path: 'distributions',
    canActivate: [ IsAuthenticatedGuard ],
    loadChildren: () => import('./pages/distributions/distributions.module').then( m => m.DistributionsPageModule)
  },
  {
    path: 'create-distribution',
    canActivate: [ IsAuthenticatedGuard ],
    loadChildren: () => import('./pages/create-distribution/create-distribution.module').then( m => m.CreateDistributionPageModule)
  },
  {
    path: 'distribution-details',
    canActivate: [ IsAuthenticatedGuard ],
    loadChildren: () => import('./pages/distribution-details/distribution-details.module').then( m => m.DistributionDetailsPageModule)
  },
  {
    path: 'messages',
    canActivate: [ IsAuthenticatedGuard ],
    loadChildren: () => import('./pages/messages/messages.module').then( m => m.MessagesPageModule)
  },
  {
    path: 'create-message',
    canActivate: [ IsAuthenticatedGuard ],
    loadChildren: () => import('./pages/create-message/create-message.module').then( m => m.CreateMessagePageModule)
  },
  {
    path: 'users',
    canActivate: [ IsAuthenticatedGuard ],
    loadChildren: () => import('./pages/users/users.module').then( m => m.UsersPageModule)
  },
  {
    path: 'create-user',
    canActivate: [ IsAuthenticatedGuard ],
    loadChildren: () => import('./pages/create-user/create-user.module').then( m => m.CreateUserPageModule)
  },
  {
    path: 'transaction-revert',
    canActivate: [ IsAuthenticatedGuard ],
    loadChildren: () => import('./pages/transaction-revert/transaction-revert.module').then( m => m.TransactionRevertPageModule)
  },
  {
    path: 'generate-cards',
    loadChildren: () => import('./pages/generate-cards/generate-cards.module').then( m => m.GenerateCardsPageModule)
  },
  {
    path: 'clearing',
    loadChildren: () => import('./pages/clearing/clearing.module').then( m => m.ClearingPageModule)
  },
  {
    path: 'card-status-schedules',
    loadChildren: () => import('./pages/card-status-schedules/card-status-schedules.module').then( m => m.CardStatusSchedulesPageModule)
  },
  {
    path: 'create-card-status-schedule',
    loadChildren: () => import('./pages/create-card-status-schedule/create-card-status-schedule.module').then( m => m.CreateCardStatusSchedulePageModule)
  },
  {
    path: 't/:hash',
    loadChildren: () => import('./pages/blank/blank.module').then( m => m.BlankPageModule),
    canActivate: [ CardUrlRequestGuard ]
  },
  {
    path: 'mass-load',
    loadChildren: () => import('./pages/mass-load/mass-load.module').then( m => m.MassLoadPageModule)
  }, 
  {
    path: 'create-terminal',
    loadChildren: () => import('./pages/create-terminal/create-terminal.module').then( m => m.CreateTerminalPageModule)
  },
  {
    path: 'terminals',
    loadChildren: () => import('./pages/terminals/terminals.module').then( m => m.TerminalsPageModule)
  },
  {
    path: 'terminal-details',
    loadChildren: () => import('./pages/terminal-details/terminal-details.module').then( m => m.TerminalDetailsPageModule)
  },
  {
    path: 'create-terminal',
    loadChildren: () => import('./pages/create-terminal/create-terminal.module').then( m => m.CreateTerminalPageModule)
  },
  {
    path: 'transactions-export',
    loadChildren: () => import('./pages/transactions-export/transactions-export.module').then( m => m.TransactionsExportPageModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot( routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [  RouterModule  ]
})
export class AppRoutingModule {}
