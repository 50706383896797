import { Injectable } from '@angular/core';
import { RequestsService } from './requests/requests.service';
import { StorageService } from './storage.service';
import { TokenService } from './token.service';
import { EventsService } from './events.service';
import { environment } from '../../environments/environment';
import { RequestsServiceV2 } from './requests/requests.v2.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private storageService: StorageService,
    private tokenService: TokenService,
    private requestsService: RequestsService,
    private requestsServiceV2: RequestsServiceV2,
    private eventsService: EventsService
  ) {

    this.requestsService.config.setBaseUrl(
      environment['innocard']['api']
    )

    this.requestsServiceV2.config.setBaseUrl(
      environment['innocard']['api_v2']
    )
  
  }

  login(
    username:string, password:string, staySigned:boolean = false
  )
  {
    return new Promise ( (resolve:any, reject:any ) => {
      this.requestsServiceV2
        .get('User/Signon', { UserName: username, Password: password, SessionStay: staySigned ? '1' : '0' })
        .then( success => {
          if ( 'undefined' == typeof success.LOINUserSignin ||
            success.LOINUserSignin['MessageCategory'] == 'E' ) {
            return reject(success);
          }
          
          this.tokenService.set(
            success.LOINUserSignin['UserSessionID'],
            password
          )
          this.storageService.set('user', success.LOINUserSignin);
          this.eventsService.publish('user:signin', success.LOINUserSignin);

          resolve(success);

        })
        .catch(err => reject(err));
    });
  }

  storeCredentials(
    username:string, password:string
  ) {
    this.storageService.set('bx', btoa(JSON.stringify({ username, password })));
  }

  getStoredCredentials() {
    try {
      return JSON.parse( atob(this.storageService.get('bx')) );
    } catch (e) {
      return null;
    }
  }

  removeCredentials() {
    return this.storageService.remove('bx');
  }

  public hasSessionStored()
  {
    let tokens = this.tokenService.get();
    
    if ( tokens.UserSessionID ) {
      this.eventsService.publish('user:signin', this.storageService.get('user') );

      return true;
    }

    return false;
  }

  logout()
  {
    return new Promise ( (resolve:any, reject:any) => {

      // async request to logout.
      this.requestsServiceV2.put('User/Signoff');
      // not needed to do anything with response

      if ( this.hasSessionStored() ) {
        this.tokenService.remove();
        this.removeCredentials();
        resolve(true);
      }
    });
    
  }
}
